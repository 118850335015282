

 .h-full {
	margin-top: 80px;
	min-height: 100dhv;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .hr-dash {
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	width: 50%;
 }

 .hr-dash:before {
	content: '';
	position: absolute;
	border-bottom: 5px dashed $color-gray-200;
	top: -3px;
	bottom: -3px;
	left: -3px;
	right: -3px;
 }

 .text-price-md
    font-size: 1rem
.text-price-lg
    font-size: 1.125rem
    font-weight: bold
    text-overflow: ellipsis

.action-confirm-container
  position: sticky
  bottom: 0
  left: 0
  width: 100%
  z-index: 8
  background-color: #fff
  border-top: 1px solid $color-gray-200


::v-deep .v-input input[type="number"]
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none;
    -moz-appearance: textfield;
.ontop-discount-container
  background-color: #f5f5f7
  border-radius: 8px
  padding 16px

.full-page
 overflow: visible !important
